import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CloseIconComponent } from "../../svg/close-icon/close-icon.component";
import { TPopupErrorType } from "./error-popup.types";
import { SafeHtmlPipe } from "../../pipes/safe-html.pipe";
import { errorsPopupCatalog } from "./errors-popup.catalog";

@Component({
    selector: 'robocode-error-popup',
    standalone: true,
    imports: [CloseIconComponent, SafeHtmlPipe],
    templateUrl: './error-popup.component.html',
    styleUrl: './error-popup.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorPopupComponent {
    @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
    @Input({ required: true }) errorType!: TPopupErrorType;

    protected readonly errorsPopupCatalog: Record<TPopupErrorType, string> = errorsPopupCatalog;
    onCloseModal($event: MouseEvent): void {
        let modal: Element | null = ($event.target as HTMLElement).closest('robocode-error-popup');
        if (modal) {
            modal.remove();
        } else {
            this.closeModal.emit();
        }
    }
}
