import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { headersInterceptor } from "./interceptors/headers/headers.interceptor";
import { ApiConfiguration } from "./api/http-services/api-configuration";
import { environment } from "../environments/environment";

export const appConfig: ApplicationConfig = {
  providers: [
      provideRouter(routes),
      provideHttpClient(
          withInterceptors([headersInterceptor])
      ),
      {
          provide: ApiConfiguration,
          useValue: {
              apiRobocodeUrl: environment.apiRobocodeUrl,
              apiIntrovertUrl: environment.apiIntrovertUrl
          }
      }
  ]
};
