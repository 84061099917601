import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { AuthenticationService } from "./services/authentication/authentication.service";
import { EnvironmentStore } from "./stores/environment/environment.store";
import { WidgetsStore } from "./stores/widgets/widgets.store";

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './app.component.html',
    styleUrl: './app.component.css'
})
export class AppComponent {
    private environmentStore = inject(EnvironmentStore);
    private widgetsStore = inject(WidgetsStore);
    title: string = 'lkApp';

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    )
  {
    this.environmentStore.loadClientData();
    this.environmentStore.loadSubscriptionData();
    this.widgetsStore.loadWidgetData();
    this.authenticationService.beginListenPostMessage();
  }
}
