import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: 'widgets',
        loadComponent: () => import('./pages/widgets/widgets.component').then((m) => m.WidgetsComponent),
    },
    {
        path: 'tarif',
        loadComponent: () => import('./pages/tariffs/tariffs.component').then((m) => m.TariffsComponent),
    },
    {
        path: 'support',
        loadComponent: () => import('./pages/support/support.component').then((m) => m.SupportComponent),
    },
];

